<template>
  <div class="flex flex-col items-center py-4">
    <div class="w-full xl:px-36 sm:px-8">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center">
          <strong>&nbsp;Mis Datos</strong>
        </h1>
        <div class="ml-auto">
          <sa-button
            type="primary"
            class="items-center px-3 py-3 font-bold rounded"
            @click="save"
            v-loading.fullscreen.lock="loading">
            Guardar
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-6">
          <el-form
            :model="form"
            :rules="rules"
            ref="myDataForm"
            v-loading="loading"
          >
            <content-card>
              <div class="w-full flex">
                <el-form-item class="w-1/3 mr-2" prop="fullname">
                  <strong>Nombre</strong>
                  <el-input v-model="form.fullname" />
                </el-form-item>
                <el-form-item class="w-1/3 mr-2" prop="email">
                  <strong>Correo Electronico</strong>
                  <el-input v-model="form.email" />
                </el-form-item>
              </div>
            </content-card>
          </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import error from '../../../mixins/error';
import { ACCOUNT_STORE } from '../../../store/modules/account/mutation-types';
import { Regex } from '../../../utils/emails';
import AdminService from '../../../services/AdminService';

export default {
  name: 'MyData',
  mixins: [
    error,
  ],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      form: {
        fullname: this.$store.state.account.fullname,
        email: this.$store.state.account.email,
      },
      rules: {},
      loading: false,
      errorMessage: undefined,
    }
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    async save() {
      if (!Regex.test(this.form.email)) {
        this.errorMessage = 'Formato de correo electronico invalido';
        return;
      }
      this.loading = true;
      try {
        const data = { fullname: this.form.fullname, email: this.form.email.toLowerCase() };
        await AdminService.MyData.update(data);
        await this.$store.dispatch(ACCOUNT_STORE, {
          ...this.$store.state.account,
          ...this.form,
        });
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>
